@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
@import url("https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:400,500|Oswald:300,500|Roboto:300,500");
/**
 * Base Styles — Icons
 */
.mv-icon, .modal .modal-header .close {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  vertical-align: middle;
}

.mv-icon.mv-icon__check, .modal .modal-header .mv-icon__check.close, .property-index__filter-form .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("../icons/icon-check.svg");
}

.mv-icon.mv-icon__check.mv-icon__check--grey, .modal .modal-header .mv-icon__check.mv-icon__check--grey.close, .property-index__filter-form .custom-control-input:checked ~ .mv-icon__check--grey.custom-control-label:after {
  background-image: url("../icons/icon-check--grey.svg");
}

.mv-icon.mv-icon__close, .modal .modal-header .mv-icon__close.close {
  background-image: url("../images/icons/icon-close.svg");
}

.mv-icon.mv-icon__close.mv-icon__close--white, .modal .modal-header .mv-icon__close.mv-icon__close--white.close {
  background-image: url("../images/icons/icon-close--white.svg");
}

.mv-icon.mv-icon__grid, .modal .modal-header .mv-icon__grid.close {
  background-image: url("../images/icons/icon-grid.svg");
}

.mv-icon.mv-icon__grid.mv-icon__grid--active, .modal .modal-header .mv-icon__grid.mv-icon__grid--active.close {
  background-image: url("../images/icons/icon-grid--active.svg");
}

.mv-icon.mv-icon__grid.mv-icon__grid--white, .modal .modal-header .mv-icon__grid.mv-icon__grid--white.close {
  background-image: url("../images/icons/icon-grid--white.svg");
}

.mv-icon.mv-icon__map, .modal .modal-header .mv-icon__map.close {
  background-image: url("../images/icons/icon-map.svg");
}

.mv-icon.mv-icon__map.mv-icon__map--active, .modal .modal-header .mv-icon__map.mv-icon__map--active.close {
  background-image: url("../images/icons/icon-map--active.svg");
}

.mv-icon.mv-icon__map.mv-icon__map--white, .modal .modal-header .mv-icon__map.mv-icon__map--white.close {
  background-image: url("../images/icons/icon-map--white.svg");
}

.mv-icon.mv-icon__hair-and-beauty, .modal .modal-header .mv-icon__hair-and-beauty.close {
  background-image: url("../images/icons/icon-hair-and-beauty.svg");
}

.mv-icon.mv-icon__banks-and-atms, .modal .modal-header .mv-icon__banks-and-atms.close {
  background-image: url("../images/icons/icon-banks-and-atms.svg");
}

.mv-icon.mv-icon__entertainment, .modal .modal-header .mv-icon__entertainment.close {
  background-image: url("../images/icons/icon-entertainment.svg");
}

.mv-icon.mv-icon__food-and-drink, .modal .modal-header .mv-icon__food-and-drink.close {
  background-image: url("../images/icons/icon-food-and-drink.svg");
}

.mv-icon.mv-icon__fitness, .modal .modal-header .mv-icon__fitness.close {
  background-image: url("../images/icons/icon-fitness.svg");
}

.mv-icon.mv-icon__footwear, .modal .modal-header .mv-icon__footwear.close {
  background-image: url("../images/icons/icon-footwear.svg");
}

.mv-icon.mv-icon__health-and-fitness, .modal .modal-header .mv-icon__health-and-fitness.close {
  background-image: url("../images/icons/icon-health-and-fitness.svg");
}

.mv-icon.mv-icon__jewellery-and-accessories, .modal .modal-header .mv-icon__jewellery-and-accessories.close {
  background-image: url("../images/icons/icon-jewellery-and-accessories.svg");
}

.mv-icon.mv-icon__kids, .modal .modal-header .mv-icon__kids.close {
  background-image: url("../images/icons/icon-kids.svg");
}

.mv-icon.mv-icon__leisure-and-gaming, .modal .modal-header .mv-icon__leisure-and-gaming.close {
  background-image: url("../images/icons/icon-leisure-and-gaming.svg");
}

.mv-icon.mv-icon__major-stores, .modal .modal-header .mv-icon__major-stores.close {
  background-image: url("../images/icons/icon-major-stores.svg");
}

.mv-icon.mv-icon__services, .modal .modal-header .mv-icon__services.close {
  background-image: url("../images/icons/icon-services.svg");
}

.mv-icon.mv-icon__souvenirs, .modal .modal-header .mv-icon__souvenirs.close {
  background-image: url("../images/icons/icon-souvenirs.svg");
}

.mv-icon.mv-icon__supermarkets, .modal .modal-header .mv-icon__supermarkets.close {
  background-image: url("../images/icons/icon-supermarkets.svg");
}

.mv-icon.mv-icon__technology-and-appliances, .modal .modal-header .mv-icon__technology-and-appliances.close {
  background-image: url("../images/icons/icon-technology-and-appliances.svg");
}

.mv-icon.mv-icon__travel, .modal .modal-header .mv-icon__travel.close {
  background-image: url("../images/icons/icon-travel.svg");
}

.mv-icon.mv-icon__homewares-and-gifts, .modal .modal-header .mv-icon__homewares-and-gifts.close {
  background-image: url("../images/icons/icon-homewares-and-gifts.svg");
}

.mv-icon.mv-icon__fashion, .modal .modal-header .mv-icon__fashion.close {
  background-image: url("../images/icons/icon-fashion.svg");
}

.mv-icon.mv-icon__offers-tag, .modal .modal-header .mv-icon__offers-tag.close {
  background-image: url("../images/icons/icon-offers-tag.svg");
}

.mv-icon.mv-icon__search, .modal .modal-header .mv-icon__search.close {
  background-image: url("../icons/icon-search.svg");
}

/**
 * Component Styles — Modal
 */
.modal {
  -webkit-overflow-scrolling: touch;
}

.modal .modal-dialog, .modal .modal-content {
  max-width: none;
  min-height: 100vh;
  margin: 0;
}

.modal .modal-content, .modal .modal-header, .modal .modal-footer {
  border: 0;
  border-radius: 0;
}

.modal .modal-header, .modal .modal-body {
  padding: 2rem;
}

.modal .modal-header .close {
  margin: 0;
  float: none;
  clear: both;
  opacity: 1;
}

.modal .modal-header .close > span {
  opacity: 0;
}

.modal .modal-title {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 0.83333em;
}

@media (max-width: 991px) {
  .modal .form-group {
    margin-bottom: 4rem;
  }
}

.modal .property-index__filter--clear {
  position: static;
  margin-bottom: 1.42857em;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1em;
}

.modal .modal-footer {
  padding: 0;
}

.modal .modal-footer .btn {
  padding: 1.11111em;
  border-radius: 0;
  border-color: #ee2737;
  background-color: #ee2737;
  font-size: 1.125em;
  font-weight: 700;
  text-transform: none;
}

.asset-index.modal-open {
  position: fixed;
}

/**
 * Component Styles — PropertyCard.jsx
 */
.property-index__card {
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.property-index__card .mv-icon, .property-index__card .modal .modal-header .close, .modal .modal-header .property-index__card .close {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  margin: 1em 1em 1.5em;
}

.property-index__card .mv-icon.mv-icon__offers-tag, .property-index__card .modal .modal-header .mv-icon__offers-tag.close, .modal .modal-header .property-index__card .mv-icon__offers-tag.close {
  position: absolute;
  top: 0;
}

.property-index__card .category-icons .mv-icon:nth-child(2), .property-index__card .category-icons .modal .modal-header .close:nth-child(2), .modal .modal-header .property-index__card .category-icons .close:nth-child(2) {
  right: 30px;
}

.property-index__card .placeholder {
  height: 80px;
}

.property-index__card .card-img-top {
  max-height: 36.6rem;
}

.property-index__card .store-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.property-index__card .store-logo .card-img {
  flex: 0 0 auto;
  width: auto;
  max-height: 118px;
}

.property-index__card .phonenumber {
  display: block;
}

.property-index__card .card-body {
  padding: 2.5rem 3.5rem;
  position: relative;
}

.property-index__card .card-body .col-auto {
  margin-bottom: 1.5rem;
}

.property-index__card .card-title {
  margin: 0;
  padding: 0;
  min-height: 3.2em;
  line-height: 1em;
  font-size: 1.8em;
  text-transform: uppercase;
}

.property-index__card .card-title a {
  color: inherit;
  text-decoration: none;
}

.property-index__card .card-title a:hover {
  color: inherit;
  text-decoration: underline;
}

.property-index__card .card-subtitle {
  min-height: 1.5em;
  margin: 0 3em 0 0;
  font-size: 0.9em;
}

.property-index__card .card-text {
  font-size: 1.125em;
  line-height: 1.11111em;
}

.property-index__card .card-text .mv-icon, .property-index__card .card-text .modal .modal-header .close, .modal .modal-header .property-index__card .card-text .close {
  margin-right: 0.44444em;
  vertical-align: bottom;
}

.property-index__card .card-footer {
  padding: 2rem;
  border-top-color: #eeeeee;
  background-color: white;
  text-align: right;
  border-top: 1px solid rgba(0, 0, 0, 0.075);
}

.property-index__card .card-footer .btn {
  width: auto;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-right: 3em;
  padding-bottom: 0;
  font-size: 1em;
  text-decoration: none;
}

.property-index__card .card-footer .btn:after {
  width: 3rem;
}

@media (max-width: 991px) {
  .property-index__card .card-body {
    padding: 3rem 1.5rem 1rem;
  }
  .property-index__card .card-title {
    min-height: 0;
    margin-bottom: 1.875em;
  }
  .property-index__card .card-subtitle {
    min-height: 0;
    margin-bottom: 1.875em;
    font-size: 1em;
    line-height: 1em;
    line-height: 1.5em;
  }
  .property-index__card .card-text {
    font-size: 0.875em;
    line-height: 1.42857em;
  }
  .property-index__card .card-footer {
    line-height: 1em;
  }
  .property-index__card .card-footer .btn {
    line-height: 1em;
  }
}

/**
 * Component Styles — PropertyIndex.jsx
 */
.property-index {
  /**
	* CONN
	* Taken from bootstrap, rather than import the whole bootstrap scss
	*/
}

.property-index > .container,
.property-index > .property-index__filters {
  padding-right: 15px;
  padding-left: 15px;
}

.property-index .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.property-index .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.property-index .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.property-index .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.property-index .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.property-index .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.property-index .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.property-index .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.property-index .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.property-index .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.property-index .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.property-index .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.property-index .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.property-index .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.property-index .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.property-index .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.property-index .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.property-index .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.property-index .custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.property-index .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.property-index .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.property-index .form-inline {
  display: flex;
}

.property-index .form-inline .form-group {
  display: flex;
  flex: 0 0 auto;
}

@media (max-width: 991px) {
  .property-index .form-inline {
    width: 100%;
  }
}

.property-index .dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
}

.property-index .dropdown-menu.show {
  display: block;
}

.property-index .btn {
  border: 1px solid transparent;
}

.property-index__filters {
  border-bottom: 1px solid #dbdbdb;
  font-size: 1em;
}

.property-index__filters .search-text {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 991px) {
  .property-index__filters .react-autosuggest__container:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 35px;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    background-image: url("../images/icons/icon-search--black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    content: '';
  }
  .property-index__filters .react-autosuggest__container .react-autosuggest__input {
    padding-right: 50px;
  }
}

@media (max-width: 575px) {
  .property-index__filters .react-autosuggest__container:after {
    right: 25px;
  }
}

.property-index__filters--secondary {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

@media (max-width: 991px) {
  .property-index__filters {
    margin-bottom: 0;
    padding: 2rem 0;
    border-bottom: 0;
  }
  .property-index__filters--secondary {
    margin-bottom: 1.6rem;
  }
  .property-index__filters--secondary .property-index__results-count {
    font-size: 1em;
    line-height: 1.5em;
  }
}

.property-index__results-count {
  position: relative;
  padding: 3rem 0;
}

.property-index .toggle-offers {
  position: absolute;
  right: 0em;
  cursor: pointer;
  padding: 0 2em 0;
}

.property-index .toggle-offers .mv-icon, .property-index .toggle-offers .modal .modal-header .close, .modal .modal-header .property-index .toggle-offers .close {
  position: absolute;
  right: 0;
  top: 4px;
}

.property-index__view {
  padding: 2rem 0;
}

.property-index__view__toggle {
  position: relative;
  margin: 0 0.27778em;
  padding: 0 1.38889em;
  transition: color 0.2s ease, opacity 0.2s ease;
  color: #000000;
  font-size: 0.8em;
  font-weight: 500;
  opacity: 0.5;
  text-decoration: none !important;
}

.property-index__view__toggle .mv-icon, .property-index__view__toggle .modal .modal-header .close, .modal .modal-header .property-index__view__toggle .close {
  position: absolute;
  top: 0;
  left: 0;
}

.property-index__view__toggle:hover, .property-index__view__toggle:focus {
  color: #000000;
  text-decoration: none;
  opacity: 1;
}

.property-index__view__toggle.active {
  color: #ee2737;
  opacity: 1;
}

.property-index__filter-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-index__filter-form form {
  flex-wrap: nowrap;
  align-items: center;
}

.property-index__filter-form form > .btn:not(.property-index__filter__refine-btn) {
  position: relative;
  flex-basis: 10rem;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 10rem;
  border-radius: 0 4px 4px 0;
  border-color: #ee2737;
  background: #ee2737;
  font-weight: 500;
}

.property-index__filter-form form > .btn:not(.property-index__filter__refine-btn) .mv-icon, .property-index__filter-form form > .btn:not(.property-index__filter__refine-btn) .modal .modal-header .close, .modal .modal-header .property-index__filter-form form > .btn:not(.property-index__filter__refine-btn) .close {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3.3rem;
  height: 3.3rem;
  transform: translate(-50%, -50%);
}

.property-index__filter-form form > .btn:not(.property-index__filter__refine-btn):hover, .property-index__filter-form form > .btn:not(.property-index__filter__refine-btn):focus {
  border-color: #d21020;
  background-color: #d21020;
  box-shadow: none;
}

.property-index__filter-form form > .btn:not(.property-index__filter__refine-btn):not(:disabled):not(.disabled):active {
  border-color: #a20d19;
  background-color: #a20d19;
  box-shadow: none;
}

.property-index__filter-form .form-group {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

@media (max-width: 991px) {
  .property-index__filter-form .form-group {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 0;
  }
  .modal-content .property-index__filter-form .form-group {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.property-index__filter-form .custom-control {
  padding-left: 2.5rem;
}

.property-index__filter-form .custom-control .mv-icon, .property-index__filter-form .custom-control .modal .modal-header .close, .modal .modal-header .property-index__filter-form .custom-control .close {
  position: absolute;
  right: 15px;
  top: 8px;
}

.property-index__filter-form .custom-control-label {
  justify-content: flex-start;
  line-height: 2.5em;
  cursor: pointer;
}

.property-index__filter-form .custom-control-label:before, .property-index__filter-form .custom-control-label:after {
  top: 50%;
  left: -3rem;
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
}

.property-index__filter-form .custom-control-label:before {
  border: 1px solid #bbbcbc;
  border-radius: 4px;
  background-color: white;
}

.property-index__filter-form .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.property-index__filter-form .custom-control-input:active ~ .custom-control-label:before {
  background-color: rgba(54, 54, 54, 0.25);
}

.property-index__filter-form .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #363636;
  background-color: #363636;
}

.property-index__filter-form .custom-control-input:checked ~ .custom-control-label:after {
  background-size: 1.4rem;
}

.property-index__filter-form .form-control {
  min-height: 6rem;
  border-radius: 0;
  border-color: #dbdbdb;
  color: #363636;
  font-size: 1em;
  font-weight: 500;
  line-height: 3.22222em;
  box-shadow: none;
  appearance: none;
}

.property-index__filter-form .form-control::placeholder {
  color: rgba(85, 86, 91, 0.7);
}

.property-index__filter-form .form-control[type='number']::-webkit-inner-spin-button, .property-index__filter-form .form-control[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

@media (max-width: 991px) {
  .property-index__filter-form .form-control {
    line-height: 2.525em;
  }
}

.property-index__filter-form select.form-control {
  cursor: pointer;
}

.property-index__filter-form .input-group-text {
  border-radius: 0;
  border-color: #dbdbdb;
  background: white;
  color: #55565b;
  font-size: 1em;
  font-weight: 500;
  line-height: 3.22222em;
}

@media (max-width: 991px) {
  .property-index__filter-form .input-group-text {
    line-height: 2.525em;
  }
}

.property-index .property-index__filter__refine-btn {
  display: flex;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0;
  border-color: #dbdbdb;
  background-color: white;
  color: #55565b;
  font-size: 1em;
  font-weight: 500;
  line-height: 3.22222em;
  text-align: center;
  text-transform: none;
}

.property-index .property-index__filter__refine-icon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 1.2rem;
  margin-right: 1rem;
}

.property-index .property-index__filter__refine-icon > div {
  display: block;
  height: 2px;
  background-color: #55565b;
}

.property-index .property-index__filter__refine-icon > div:first-child {
  width: 1.8rem;
}

.property-index .property-index__filter__refine-icon > div:nth-child(2) {
  width: 1rem;
}

.property-index .property-index__filter__refine-icon > div:nth-child(3) {
  width: 0.4rem;
}

.property-index__filter--state {
  width: 250px;
}

.property-index__filter--state .dropdown-toggle {
  width: 205px;
}

.property-index__filter--state .dropdown-menu {
  width: 56em;
}

.property-index__filter--state .dropdown-menu .row {
  margin-left: 5px;
}

.property-index__filter--state > label:not(.property-index__filter--clear) {
  justify-content: flex-start;
  margin-bottom: 0.9375em;
  font-size: 0.88889em;
  font-weight: 500;
  line-height: 1.25em;
  text-transform: uppercase;
}

.property-index .dropdown {
  flex-basis: 100%;
  width: 100%;
}

.property-index .dropdown-toggle {
  display: block;
  width: 100%;
  padding: 1em 2em 1em 1em;
  border-radius: 0;
  border-color: #dbdbdb;
  background-color: white;
  color: #55565b;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1em;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: none;
  overflow: hidden;
}

.property-index .dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 0.66667em;
  transform: translateY(-50%);
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.property-index .dropdown-toggle:focus {
  box-shadow: none;
}

.property-index .dropdown-toggle[aria-expanded='true']:after {
  transform: translateY(-50%) rotateZ(180deg);
}

@keyframes slideDown {
  0% {
    top: -3rem;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.property-index .dropdown-menu {
  min-width: 32.2rem;
  max-width: 56rem;
  margin-top: 1.5rem;
  padding: 2rem 0 2rem;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  font-size: 0.88889em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  animation: slideDown 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .property-index .dropdown-menu {
    width: 32rem;
  }
}

.property-index .dropdown-menu:before {
  display: block;
  position: absolute;
  top: -11px;
  left: 15px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border: 1px solid #dbdbdb;
  border-right: 0;
  border-bottom: 0;
  background: white;
  content: '';
}

.property-index .dropdown-menu[data-placement='top-start'] {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.property-index .dropdown-menu[data-placement='top-start']:before {
  top: auto;
  bottom: -11px;
  transform: rotate(-135deg);
}

.property-index .dropdown-menu[data-placement='top-end'] {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.property-index .dropdown-menu[data-placement='top-end']:before {
  top: auto;
  right: 15px;
  bottom: -11px;
  left: auto;
  transform: rotate(-135deg);
}

.property-index .dropdown-menu[data-placement='bottom-end']:before {
  right: 15px;
  left: auto;
}

.property-index__filter--clear {
  display: block;
  position: absolute;
  right: 3rem;
  color: #55565b;
  font-size: 0.875em;
  font-weight: 500;
  text-align: right;
  cursor: pointer;
}

.property-index .property-index__sort {
  flex: 0 0 auto;
}

.property-index .property-index__sort .dropdown-menu {
  padding-top: 3.5rem;
  padding-right: 0;
  padding-left: 0;
  font-size: 1em;
}

.property-index .property-index__sort .dropdown-item {
  padding: 1em 1.875em;
  transition: all 0.2s ease-in-out;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1em;
  cursor: pointer;
  display: block;
  width: 100%;
  clear: both;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-align: inherit;
}

.property-index .property-index__sort .dropdown-item:hover, .property-index .property-index__sort .dropdown-item:focus {
  background-color: #eeeeee;
}

.property-index .property-index__sort .dropdown-item:active, .property-index .property-index__sort .dropdown-item.active {
  background-color: #eeeeee;
  color: #ee2737;
}

.property-index .property-index__results-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8rem;
}

@media (max-width: 767px) {
  .property-index .property-index__results-grid {
    margin-bottom: 3rem;
  }
}

.property-index .property-index__results__item {
  margin-bottom: 3rem;
  position: relative;
}

.property-index .property-index__results-reveal {
  margin-bottom: 12rem;
  text-align: center;
}

.property-index .property-index__results-reveal .btn {
  margin-bottom: 0;
  font-size: 0.88889em;
  text-decoration: none;
}

.property-index__view--mobile {
  position: sticky;
  bottom: -1px;
  width: 100%;
}

.property-index__view--mobile .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.11111em;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-color: #ee2737;
  background-color: #ee2737;
  color: white;
  font-size: 1.125em;
  font-weight: 500;
  text-transform: none;
}

.property-index__view--mobile .btn .mv-icon, .property-index__view--mobile .btn .modal .modal-header .close, .modal .modal-header .property-index__view--mobile .btn .close {
  margin-right: 0.55556em;
}

.property-index__empty-results .btn {
  font-size: 1em;
  line-height: 1em;
  text-decoration: none;
}

.property-index .mv-icon, .property-index .modal .modal-header .close, .modal .modal-header .property-index .close {
  opacity: 0.6;
}

.property-index .btn:focus {
  box-shadow: none;
}

.rich-text .react-autosuggest__container {
  position: relative;
}

.rich-text .react-autosuggest__container ul li:before {
  display: none;
}

@media (max-width: 991px) {
  .rich-text .react-autosuggest__container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .rich-text .react-autosuggest__container {
    padding: 0;
  }
}

.rich-text .react-autosuggest__input {
  width: 240px;
  height: 50px;
  padding: 0 20px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  border: 1px solid #dbdbdb;
  appearance: none;
  color: #55565b;
}

@media (min-width: 992px) {
  .rich-text .react-autosuggest__input {
    border-left: 0;
  }
}

@media (max-width: 991px) {
  .rich-text .react-autosuggest__input {
    width: 100%;
  }
}

.rich-text .react-autosuggest__input--focused {
  outline: none;
}

.rich-text .react-autosuggest__input::-ms-clear {
  display: none;
}

.rich-text .react-autosuggest__suggestions-container {
  display: none;
}

.rich-text .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 49px;
  width: 240px;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  font-weight: 500;
  font-size: 20px;
  z-index: 2;
}

.rich-text .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.rich-text .react-autosuggest__suggestion {
  margin-top: 0;
  padding: 10px 20px;
  font-size: 0.8em;
  cursor: pointer;
}

.rich-text .react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.rich-text .react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.rich-text .react-autosuggest__section-container--first {
  border-top: 0;
}

.rich-text .react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

#retail-index-google-map {
  display: none;
  height: 500px !important;
}
